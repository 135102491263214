import { Card, Table, Typography } from "antd";
import { CSSProperties } from "react";
import { columns1 } from "./mock";

const headingText: CSSProperties = {
  fontSize: "18px",
  fontWeight: "700",
  marginBottom: "10px",
  paddingTop: "5px",
};

const Table1 = ({
  pricingData1,
  selectedRowKeys,
  onSelectChange,
  title,
}: {
  pricingData1: IPricingData[];
  selectedRowKeys: React.Key[];
  onSelectChange: (selectedRowKeys: React.Key[], rows: any, info: any) => void;
  title: string;
}) => {
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  return (
    <>
      <Card style={{ marginBottom: "25px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "1rem",
            gap: "20px",
          }}
        >
          <Typography style={headingText}>{title}</Typography>
        </div>
        <Table
          columns={columns1(title === "Vacant Units" ? "vacant" : "occupied")}
          rowKey="pk_id"
          dataSource={pricingData1}
          pagination={{ pageSize: 20, hideOnSinglePage: true }}
          rowSelection={rowSelection}
          scroll={{ x: 1024 }}
        />
      </Card>
    </>
  );
};

export default Table1;
