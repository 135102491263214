/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, Select } from "antd";
import { useCallback, useEffect, useState } from "react";

import Table1 from "./table1";
import {
  extractOptions,
  transformPricingData,
} from "../../../utils/transform-data";
import AppButton from "../../../components/button";
import {
  getPricingData,
  postPricingData,
} from "../../../services/pricing.service";
import { Loader } from "../../../components";
import toast from "react-hot-toast";
import { asyncGetUserProperties } from "../../../store/renewal/renewal-async";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { CheckboxChangeEvent } from "antd/es/checkbox";

function PricingTable(): ReactNode {
  const [selectedRowKeys1, setSelectedRowKeys1] = useState<React.Key[][]>([]);
  const [transformedData, setTransformedData] = useState<any>({});
  const [selectAll, setSelectAll] = useState(false);
  const [loader, setLoader] = useState<boolean>(true);
  const [data, setData] = useState([]);
  const dispatch = useAppDispatch();
  const [filter, setFilter] = useState({
    bedroom: "",
    property: "",
  });

  const { user, token }: { [key: string]: any } = useAppSelector((s) => s.user);
  const [bedroomOptions, setBedroomOptions] = useState<Item[] | []>([]);
  const [propertyOptions, setPropertyOptions] = useState<Item[] | []>([]);

  const fetchPricingData = async (
    id: string,
    bedrooms?: string | undefined
  ) => {
    setLoader(true);
    try {
      const res: any = await getPricingData(
        {
          propertyId: id ?? "",
          bedrooms: bedrooms !== "" ? bedrooms : undefined,
        },
        token
      );
      setLoader(false);
      setData(res.data);
    } catch (error) {
      setLoader(false);
      toast.error("Something went wrong during fetching data");
    }
  };

  useEffect(() => {
    if (filter.property) {
      fetchPricingData(
        filter.property,
        filter.bedroom ? filter.bedroom : undefined
      );
    }
  }, [filter.property, filter.bedroom]);

  const onSelectChange1 = (
    index: number,
    newSelectedRowKeys: React.Key[],
    option: any
  ) => {
    setSelectedRowKeys1((prev) => {
      const newKeys = prev.map((item, i) => {
        if (i === index) {
          return newSelectedRowKeys;
        }
        return item;
      });

      return newKeys;
    });
  };

  const handleSelectAllChange = (e: CheckboxChangeEvent) => {
    const checked = e.target.checked;
    setSelectAll(checked);

    const allUnitIDs: React.Key[][] = Object.keys(transformedData).map(
      (key) => {
        return transformedData[key].map((item: any) => item.pk_id);
      }
    );

    setSelectedRowKeys1(
      checked ? allUnitIDs : Object.keys(transformedData).map(() => [])
    );
  };

  const getUserProperties = async () => {
    try {
      const userProperies = await dispatch(
        asyncGetUserProperties(`${user?.idTokenClaims?.oid}`)
      ).unwrap();
      setPropertyOptions(
        userProperies.data
          .map((obj: any) => ({
            label: obj.property_name,
            value: obj.ms_property_id,
          }))
          .sort((a: any, b: any) => a.label.localeCompare(b.label))
      );

      setFilter({ ...filter, property: userProperies.data[0].ms_property_id });
    } catch (error) {}
  };

  useEffect(() => {
    if (user) {
      getUserProperties();
    }
  }, [user]);

  const handleApprove = async () => {
    try {
      const filteredData = data.filter((item: any) =>
        selectedRowKeys1.flat(1).includes(item.pk_id)
      );

      setLoader(true);
      await postPricingData(filteredData);
      toast.success("post function triggered successfully");
      await fetchPricingData(
        filter.property,
        filter.bedroom ? filter.bedroom : undefined
      );
      setLoader(false);
      setSelectedRowKeys1([]);
      setSelectAll(false);
    } catch (err: any) {
      setLoader(false);
      toast.error(err?.message || err || "Something went wrong1");
    }
  };

  const handleTransformData = useCallback(() => {
    const res = transformPricingData(data);
    // const bedroomsOptions = extractOptions(data);
    setTransformedData(res);
    const tempArr: React.Key[][] = [];
    Object.keys(res).forEach(() => {
      tempArr.push([]);
    });
    setSelectedRowKeys1(tempArr);
    // setBedroomOptions(bedroomsOptions);
    setBedroomOptions([
      {
        label: "All",
        value: "",
      },
      {
        label: "1 Bedroom",
        value: "1",
      },
      {
        label: "2 Bedrooms",
        value: "2",
      },
      {
        label: "3 Bedrooms",
        value: "3",
      },
    ]);
  }, [data]);

  useEffect(() => {
    handleTransformData();
  }, [handleTransformData]);

  const handleFilter = (val: string, name: string) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: val,
    }));
  };

  if (loader) {
    return <Loader />;
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "1rem",
          gap: "20px",
        }}
      >
        <Select
          placeholder="Property"
          options={propertyOptions}
          value={filter.property}
          onChange={(val: string) => handleFilter(val, "property")}
          style={{ width: 350 }}
        />
        <Select
          value={filter.bedroom}
          options={[...bedroomOptions]}
          onChange={(val: string) => handleFilter(val, "bedroom")}
          style={{ width: "150px" }}
          allowClear
        />
        <Checkbox
          checked={selectAll}
          onChange={handleSelectAllChange}
          style={{
            border: "1px solid #d9d9d9",
            borderRadius: "6px",
            padding: "4px 10px",
          }}
        >
          Select All
        </Checkbox>
      </div>
      {data.length === 0 && !loader && (
        <div style={{ padding: "20px" }}>No data found</div>
      )}
      {data.length > 0 && !loader && (
        <>
          {Object.keys(transformedData).map((key, index) => {
            return (
              <Table1
                key={index}
                pricingData1={transformedData[key]}
                selectedRowKeys={selectedRowKeys1[index]}
                onSelectChange={(selectedKey, rows, info) =>
                  onSelectChange1(index, selectedKey, rows)
                }
                title={key}
              />
            );
          })}

          {data.length && (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <AppButton
                btnType="primary"
                value="Approve"
                onClick={handleApprove}
                isDisabled={selectedRowKeys1.flat(1).length === 0}
              />
            </div>
          )}
        </>
      )}
    </>
  );
}

export default PricingTable;
