import React, { useState, useMemo } from "react";
import {
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Scatter,
  ResponsiveContainer,
} from "recharts";
import { Typography } from "antd";

interface ScatterPlotProps {
  data: {
    filteredChartData: any[];
    uniqueProperties: string[];
    colors: string[];
    showSelectedProperty: boolean;
    selectedPropertyId: string;
    propertyOptions: Item[];
  };
  title?: string;
  height?: number;
}

const linearRegression = (data: any[]) => {
  const n = data.length;
  const sumX = data.reduce((sum, point) => sum + point.x, 0);
  const sumY = data.reduce((sum, point) => sum + point.y, 0);
  const sumXY = data.reduce((sum, point) => sum + point.x * point.y, 0);
  const sumXX = data.reduce((sum, point) => sum + point.x * point.x, 0);

  const slope = (n * sumXY - sumX * sumY) / (n * sumXX - sumX * sumX);
  const intercept = (sumY - slope * sumX) / n;

  return { slope, intercept };
};

const roundToNearest5 = (num: number) => {
  return Math.round(num / 5) * 5;
};

const CustomTooltip: React.FC<any> = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div
        style={{
          backgroundColor: "#fff",
          border: `1px solid ${payload[0].color}`,
          borderRadius: "5px",
          padding: "10px",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <p style={{ color: payload[0].color, fontWeight: "bold" }}>
          {data.property}
        </p>
        <p>Market Rent: ${data.y?.toLocaleString()}</p>
        <p>Unit Size: {data.x?.toLocaleString()} SqFt</p>
        <p>Floor Plan: {data.floorplan_name || ""}</p>
        <p>Unit Number: {data.unit_number || ""}</p>
        <p>Date Available: {data.available || ""}</p>
      </div>
    );
  }
  return null;
};

const ScatterPlot: React.FC<ScatterPlotProps> = ({ data, title, height }) => {
  const [selectedProperty, setSelectedProperty] = useState<string | null>(null);

  const property = data?.propertyOptions.find(
    (item) => item?.value === data?.selectedPropertyId
  );

  const formatYAxis = (value: number) => {
    // First round to nearest 5
    const roundedValue = Math.round(value / 5) * 5;
    // Then format with commas and dollar sign
    return `$${roundedValue.toLocaleString()}`;
  };

  const formatXAxis = (value: number) => {
    const roundedValue = Math.round(value / 5) * 5;
    return roundedValue.toLocaleString();
  };

  // Calculate domain bounds with padding and rounding
  const axisBounds = useMemo(() => {
    const minX = Math.min(
      ...data.filteredChartData.map((point: any) => point.x)
    );
    const maxX = Math.max(
      ...data.filteredChartData.map((point: any) => point.x)
    );
    const minY = Math.min(
      ...data.filteredChartData.map((point: any) => point.y)
    );
    const maxY = Math.max(
      ...data.filteredChartData.map((point: any) => point.y)
    );

    const xPadding = (maxX - minX) * 0.1;
    const yPadding = (maxY - minY) * 0.1;

    return {
      xMin: roundToNearest5(Math.floor((minX - xPadding) * 0.9)),
      xMax: roundToNearest5(Math.ceil((maxX + xPadding) * 1.1)),
      yMin: roundToNearest5(Math.floor((minY - yPadding) * 0.9)),
      yMax: roundToNearest5(Math.ceil((maxY + yPadding) * 1.1)),
    };
  }, [data.filteredChartData]);

  // Calculate regression line
  const regressionLine = useMemo(() => {
    const visibleData = data.filteredChartData?.filter((item: any) =>
      data?.showSelectedProperty ? true : item.property !== property?.label
    );

    if (visibleData?.length > 1) {
      const { slope, intercept } = linearRegression(visibleData);
      return [
        { x: axisBounds.xMin, y: slope * axisBounds.xMin + intercept },
        { x: axisBounds.xMax, y: slope * axisBounds.xMax + intercept },
      ];
    }
    return null;
  }, [data.filteredChartData, data.showSelectedProperty, property, axisBounds]);

  if (data.filteredChartData.length === 0) {
    return (
      <div style={{ minHeight: "100px" }}>
        <Typography.Title level={5} style={{ marginTop: 0 }}>
          {title}
        </Typography.Title>
        <Typography.Text style={{ color: "lightgray" }}>
          No data
        </Typography.Text>
      </div>
    );
  }

  return (
    <>
      <div style={{ width: "100%", height: height || 400 }}>
        <ResponsiveContainer>
          <ComposedChart margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis
              type="number"
              dataKey="x"
              name="Square Feet"
              unit=" SqFt"
              label={{ value: "Square Feet", position: "bottom" }}
              domain={[axisBounds.xMin, axisBounds.xMax]}
              tickFormatter={formatXAxis}
            />
            <YAxis
              type="number"
              name="Price"
              tickFormatter={formatYAxis}
              label={{ value: "Price", angle: -90, position: "left" }}
              domain={[axisBounds.yMin, axisBounds.yMax]}
            />
            <Tooltip content={<CustomTooltip />} />
            {data.uniqueProperties
              ?.filter((prop) =>
                data?.showSelectedProperty ? true : prop !== property?.label
              )
              .map((property, index) => (
                <Scatter
                  key={property}
                  name={property}
                  data={data.filteredChartData.filter(
                    (item) => item.property === property
                  )}
                  stroke="#FFFFFF"
                  strokeWidth={1}
                  r={6}
                  dataKey="y"
                  fill={data.colors[index]}
                  opacity={
                    selectedProperty
                      ? selectedProperty === property
                        ? 1
                        : 0.1
                      : 1
                  }
                />
              ))}
            {regressionLine && (
              <Line
                type="linear"
                data={regressionLine}
                dataKey="y"
                stroke="#4361F9"
                strokeWidth={4}
                dot={false}
                activeDot={false}
                legendType="none"
                strokeLinecap="round"
              />
            )}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          marginTop: "20px",
        }}
      >
        {data.uniqueProperties
          ?.filter((prop) =>
            data?.showSelectedProperty ? true : prop !== property?.label
          )
          .map((property, index) => (
            <div
              key={property}
              onClick={() =>
                setSelectedProperty(
                  selectedProperty === property ? null : property
                )
              }
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0 10px 10px 0",
                cursor: "pointer",
                opacity: selectedProperty
                  ? selectedProperty === property
                    ? 1
                    : 0.3
                  : 1,
                fontWeight: selectedProperty === property ? "bold" : "normal",
              }}
            >
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  backgroundColor: data.colors[index],
                  border: "1px solid #FFFFFF",
                  marginRight: "5px",
                }}
              />
              <span>{property}</span>
            </div>
          ))}
      </div>
    </>
  );
};

export default ScatterPlot;
