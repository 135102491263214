/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Typography,
} from "antd";
import classes from "./styles.module.scss";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { asyncGetUserProperties } from "../../../../store/renewal/renewal-async";
import {
  addIncomePlus,
  getCompetitorOptions,
  populateFormData,
} from "../../../../services/income.services";
import toast from "react-hot-toast";
import moment from "moment";

const { TextArea } = Input;

const InputForm = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { user, token }: { [key: string]: any } = useAppSelector((s) => s.user);
  const [propertyOptions, setPropertyOptions] = useState<Item[] | []>([]);
  const [competitorOptions, setCompetitorOptions] = useState<CompItem[] | []>(
    []
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [lastUpdated, setLastUpdated] = useState<string | null>(null);
  const [compPhone, setCompPhone] = useState<string>("");

  const getUserProperties = async () => {
    try {
      const userProperies = await dispatch(
        asyncGetUserProperties(`${user?.idTokenClaims?.oid}`)
      ).unwrap();
      setPropertyOptions(
        userProperies.data.map((obj: any) => ({
          label: obj.property_name,
          value: obj.ms_property_id,
        }))
      );
    } catch (error) {}
  };

  const getCompetitors = async (selectedPropertyId: string) => {
    try {
      const options: any = await getCompetitorOptions(
        {
          propertyId: selectedPropertyId ?? "",
        },
        token
      );

      console.log("res options------>", options);

      setCompetitorOptions(
        options?.data?.map((obj: any) => ({
          label: obj.property_name,
          value: obj.dv_property_id,
          phone_number: obj.phone_number,
        }))
      );
      setCompPhone("");
      setLastUpdated(null);
    } catch (error) {
      setCompetitorOptions([]);
      setCompPhone("");
      setLastUpdated(null);
      console.log(
        "Something went wrong during fetching competitors data",
        error
      );
    }
  };

  const populateForm = async (competitorId: string) => {
    try {
      const phone = competitorOptions.find(
        (obj) => obj.value === competitorId
      )?.phone_number;

      setCompPhone(phone ? phone : "");

      const res: any = await populateFormData(
        {
          propertyId: form.getFieldValue("propertyId") ?? "",
          competitorId,
        },
        token
      );

      if (Object.keys(res.data).length === 0) {
        setLastUpdated(null);
        form.resetFields([
          "occupancy",
          "apartmentDeposit",
          "adminFee",
          "petNonRefundable",
          "applicationFee",
          "petMonthlyFee",
          "petMonthlyNotes",
          "carportUnderground",
          "carportUndergroundNotes",
          "valetWasteFee",
          "valetWasteNotes",
          "mediaAutomationFee",
          "mediaAutomationNotes",
          "garageFee",
          "garageNotes",
          "storageFee",
          "insuranceWaiverFee",
          "bikeStorage",
          "hoaFee",
          "otherFee",
          "otherNotes",
        ]);
        return;
      }

      if (res.data) {
        const { data } = res;
        const formData = {
          occupancy: data?.Occupancy,
          apartmentDeposit: data?.ApartmentDeposit,
          adminFee: data?.AdminFee,
          petNonRefundable: data?.PetNonRefundable,
          applicationFee: data?.ApplicationFee,
          petMonthlyFee: data?.PetMonthlyFee,
          petMonthlyNotes: data?.PetMonthlyNotes,
          carportUnderground: data?.CarportUnderground,
          carportUndergroundNotes: data?.CarportUndergroundNotes,
          valetWasteFee: data?.ValetWasteFee,
          valetWasteNotes: data?.ValetWasteNotes,
          mediaAutomationFee: data?.MediaAutomationFee,
          mediaAutomationNotes: data?.MediaAutomationNotes,
          garageFee: data?.GarageFee,
          garageNotes: data?.GarageNotes,
          storageFee: data?.StorageFee,
          insuranceWaiverFee: data?.InsuranceWaiverFee,
          bikeStorage: data?.BikeStorage,
          hoaFee: data?.HOAFee,
          otherFee: data?.OtherFee,
          otherNotes: data?.OtherNotes,
        };

        form.setFieldsValue(formData);
        setLastUpdated(
          moment(res.data?.CreatedUTC).format("MM/DD/YYYY") ?? null
        );
      }
    } catch (error) {
      toast.error("Something went wrong during populating form data");
    }
  };

  const handleFinish = async (values: any) => {
    setLoading(true);
    try {
      const res: any = await addIncomePlus(values, token);
      if (res.data.success) {
        toast.success("Successfully updated!");
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Updating data failed");
    }
  };

  const handleResetExceptFirst = () => {
    const propertyIdValue = form.getFieldValue("propertyId");

    form.resetFields();
    form.setFieldsValue({
      propertyId: propertyIdValue,
    });
  };

  useEffect(() => {
    handleResetExceptFirst();
  }, [form.getFieldValue("propertyId")]);

  useEffect(() => {
    if (user) {
      getUserProperties();
    }
  }, [user]);

  return (
    <div style={{ maxWidth: "2000px" }}>
      {/* <Typography.Title level={3} style={{ margin: 0, marginBottom: 8 }}>
        Income+
      </Typography.Title> */}
      <div className={classes.lastUpdated}>
        {lastUpdated && (
          <span>
            Last updated: <span className={classes.val}>{lastUpdated}</span>
          </span>
        )}

        {compPhone && (
          <span>
            Competitor Phone:{" "}
            <span className={classes.val}>{`${compPhone.slice(
              0,
              3
            )}-${compPhone.slice(3, 6)}-${compPhone.slice(6)}`}</span>{" "}
          </span>
        )}
      </div>
      <div className={classes.container}>
        <Form form={form} layout="vertical" onFinish={handleFinish}>
          <Row gutter={[40, 0]}>
            <Col xs={24} lg={12}>
              <Typography.Title level={4} className={classes.title}>
                General
              </Typography.Title>
              <hr />

              <Form.Item
                name="propertyId"
                label="Property"
                rules={[{ required: true, message: "Property is required" }]}
              >
                <Select
                  placeholder="Select a property"
                  options={propertyOptions}
                  allowClear
                  onChange={getCompetitors}
                  // onClear={() => getCompetitors("")}
                />
              </Form.Item>
              <Form.Item
                name="competitorId"
                label="Competitor"
                rules={[{ required: true, message: "Competitor is required" }]}
              >
                <Select
                  placeholder="Select a competitor"
                  options={competitorOptions}
                  allowClear
                  onChange={populateForm}
                />
              </Form.Item>
              <Form.Item
                name="occupancy"
                label="Occupancy (%)"
                rules={[{ required: true, message: "Occupancy is required" }]}
              >
                <InputNumber
                  type="number"
                  placeholder="92%"
                  style={{ width: "100%" }}
                  min={0}
                  onWheel={(e) => e.currentTarget.blur()}
                />
              </Form.Item>

              <Typography.Title level={4} className={classes.title}>
                Move-in Fees
              </Typography.Title>
              <hr />

              <Form.Item
                name="apartmentDeposit"
                label="Apartment Deposit"
                rules={[
                  { required: true, message: "Apartment Deposit is required" },
                ]}
              >
                <InputNumber
                  placeholder="$550"
                  style={{ width: "100%" }}
                  type="number"
                  min={0}
                  onWheel={(e) => e.currentTarget.blur()}
                />
              </Form.Item>

              <Form.Item
                name="adminFee"
                label="Admin Fee"
                rules={[{ required: true, message: "Admin Fee is required" }]}
              >
                <InputNumber
                  placeholder="$550"
                  style={{ width: "100%" }}
                  type="number"
                  min={0}
                  onWheel={(e) => e.currentTarget.blur()}
                />
              </Form.Item>

              <Form.Item
                name="petNonRefundable"
                label="Pet Non-refundable Fee"
                rules={[
                  {
                    required: true,
                    message: "Pet Non-refundable Fee is required",
                  },
                ]}
              >
                <InputNumber
                  placeholder="$550"
                  style={{ width: "100%" }}
                  type="number"
                  min={0}
                  onWheel={(e) => e.currentTarget.blur()}
                />
              </Form.Item>

              <Form.Item
                name="applicationFee"
                label="Application Fee"
                rules={[
                  { required: true, message: "Application Fee is required" },
                ]}
              >
                <InputNumber
                  placeholder="$550"
                  style={{ width: "100%" }}
                  type="number"
                  min={0}
                  onWheel={(e) => e.currentTarget.blur()}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Typography.Title level={4} className={classes.title}>
                Monthly Fees
              </Typography.Title>
              <hr />

              <Row gutter={[20, 0]}>
                <Col xs={24} lg={12}>
                  <Form.Item
                    name="petMonthlyFee"
                    label="Pet Monthly Fee"
                    rules={[
                      {
                        required: true,
                        message: "Pet Monthly Fee is required",
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder="$550"
                      style={{ width: "100%" }}
                      type="number"
                      min={0}
                      onWheel={(e) => e.currentTarget.blur()}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item name="petMonthlyNotes" label="Pet Monthly Notes">
                    <TextArea
                      rows={1}
                      autoSize={{ maxRows: 3 }}
                      placeholder="Pet monthly notes"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[20, 0]}>
                <Col xs={24} lg={12}>
                  <Form.Item
                    name="carportUnderground"
                    label="Carport/Underground Fee"
                    rules={[
                      {
                        required: true,
                        message: "Carport/Underground Fee is required",
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder="$550"
                      style={{ width: "100%" }}
                      type="number"
                      min={0}
                      onWheel={(e) => e.currentTarget.blur()}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item
                    name="carportUndergroundNotes"
                    label="Carport/Underground Notes"
                  >
                    <TextArea
                      rows={1}
                      autoSize={{ maxRows: 3 }}
                      placeholder="Carport/underground notes"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[20, 0]}>
                <Col xs={24} lg={12}>
                  <Form.Item
                    name="valetWasteFee"
                    label="Valet Waste Fee"
                    rules={[
                      {
                        required: true,
                        message: "Valet Waste Fee is required",
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder="$550"
                      style={{ width: "100%" }}
                      type="number"
                      min={0}
                      onWheel={(e) => e.currentTarget.blur()}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item name="valetWasteNotes" label="Valet Waste Notes">
                    <TextArea
                      rows={1}
                      autoSize={{ maxRows: 3 }}
                      placeholder="Valet waste notes"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[20, 0]}>
                <Col xs={24} lg={12}>
                  <Form.Item
                    name="mediaAutomationFee"
                    label="Media / Automation Fee"
                    rules={[
                      {
                        required: true,
                        message: "Media / Automation Fee is required",
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder="$550"
                      style={{ width: "100%" }}
                      type="number"
                      min={0}
                      onWheel={(e) => e.currentTarget.blur()}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item
                    name="mediaAutomationNotes"
                    label="Media / Automation Notes"
                  >
                    <TextArea
                      rows={1}
                      autoSize={{ maxRows: 3 }}
                      placeholder="Media/automation notes"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[20, 0]}>
                <Col xs={24} lg={12}>
                  <Form.Item
                    name="garageFee"
                    label="Garage Fee"
                    rules={[
                      {
                        required: true,
                        message: "Garage Fee is required",
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder="$550"
                      style={{ width: "100%" }}
                      type="number"
                      min={0}
                      onWheel={(e) => e.currentTarget.blur()}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item name="garageNotes" label="Garage Notes">
                    <TextArea
                      rows={1}
                      autoSize={{ maxRows: 3 }}
                      placeholder="Garage notes"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[20, 0]}>
                <Col xs={24} lg={12}>
                  <Form.Item
                    name="storageFee"
                    label="Storage Fee"
                    rules={[
                      { required: true, message: "Storage Fee is required" },
                    ]}
                  >
                    <InputNumber
                      placeholder="$550"
                      style={{ width: "100%" }}
                      type="number"
                      min={0}
                      onWheel={(e) => e.currentTarget.blur()}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item
                    name="insuranceWaiverFee"
                    label="Insurance Waiver Fee"
                    rules={[
                      {
                        required: true,
                        message: "Insurance Waiver Fee is required",
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder="$550"
                      style={{ width: "100%" }}
                      type="number"
                      min={0}
                      onWheel={(e) => e.currentTarget.blur()}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[20, 0]}>
                <Col xs={24} lg={12}>
                  <Form.Item
                    name="bikeStorage"
                    label="Bike Storage Fee"
                    rules={[
                      {
                        required: true,
                        message: "Bike Storage Fee is required",
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder="$550"
                      style={{ width: "100%" }}
                      type="number"
                      min={0}
                      onWheel={(e) => e.currentTarget.blur()}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item
                    name="hoaFee"
                    label="HOA Fee"
                    rules={[{ required: true, message: "HOA Fee is required" }]}
                  >
                    <InputNumber
                      placeholder="$550"
                      style={{ width: "100%" }}
                      type="number"
                      min={0}
                      onWheel={(e) => e.currentTarget.blur()}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[20, 0]}>
                <Col xs={24} lg={12}>
                  <Form.Item
                    name="otherFee"
                    label="Other Fees"
                    rules={[
                      { required: true, message: "Other Fees is required" },
                    ]}
                  >
                    <InputNumber
                      placeholder="$550"
                      style={{ width: "100%" }}
                      type="number"
                      min={0}
                      onWheel={(e) => e.currentTarget.blur()}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item name="otherNotes" label="Other Notes">
                    <TextArea
                      rows={1}
                      autoSize={{ maxRows: 3 }}
                      placeholder="Other notes"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Button loading={loading} type="primary" htmlType="submit">
              Submit
            </Button>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default InputForm;
